<template>
  <div class="success-message">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 489.543 489.543"
      style="enable-background:new 0 0 489.543 489.543;"
      xml:space="preserve">
      <g>
        <path
          d="M270.024,0c-22.6,0-15,48.3-15,48.3s-48.3,133.2-94.5,168.7c-9.9,10.4-16.1,21.9-20,31.3l0,0l0,0
      c-0.9,2.3-1.7,4.5-2.4,6.5c-3.1,6.3-9.7,16-23.8,24.5l46.2,200.9c0,0,71.5,9.3,143.2,7.8c28.7,2.3,59.1,2.5,83.3-2.7
      c82.2-17.5,61.6-74.8,61.6-74.8c44.3-33.3,19.1-74.9,19.1-74.9c39.4-41.1,0.7-75.6,0.7-75.6s21.3-33.2-6.2-58.3
      c-34.3-31.4-127.4-10.5-127.4-10.5l0,0c-6.5,1.1-13.4,2.5-20.8,4.3c0,0-32.2,15,0-82.7C346.324,15.1,292.624,0,270.024,0z" />
        <path
          d="M127.324,465.7l-35-166.3c-2-9.5-11.6-17.3-21.3-17.3h-66.8l-0.1,200.8h109.1C123.024,483,129.324,475.2,127.324,465.7z" />
      </g>
    </svg>
    <span class="circle circle-white" />
    <span class="circle circle-dark" />
    <span class="circle circle-container"><span class="circle circle-dark-dashed" /></span>
  </div>
</template>

<script>
  export default {
    mounted () {
      this.animation()
    },
    methods: {
      animation () {
        let like = () => {
          this.anime({
            targets: '.success-message svg',
            scale: [0, 1],
            opacity: [0, 1],
            duration: 1100
          })
        }

        this.anime.timeline({loop: false})
          .add({
            targets: '.success-message .circle-white',
            scale: [0, 3],
            opacity: [1, 0],
            easing: 'easeInOutExpo',
            rotateZ: 360,
            duration: 1100
          }).add({
            targets: '.success-message .circle-container',
            scale: [0, 1],
            duration: 1100,
            easing: 'easeInOutExpo',
            offset: '-=1000',
            complete: like
          }).add({
            targets: '.success-message .circle-dark',
            scale: [0, 1],
            duration: 1100,
            easing: 'easeOutExpo',
            offset: '-=600'
          }).add({
            targets: '.success-message .letters-left',
            scale: [0, 1],
            duration: 1200,
            offset: '-=550'
          }).add({
            targets: '.success-message .bang',
            scale: [0, 1],
            rotateZ: [45, 15],
            duration: 1200,
            offset: '-=1000'
          // }).add({
          //   targets: '.success-message',
          //   opacity: 0,
          //   duration: 1000,
          //   easing: 'easeOutExpo',
          //   delay: 1400
          })

        this.anime({
          targets: '.success-message .circle-dark-dashed',
          rotateZ: 360,
          duration: 8000,
          easing: 'linear',
          loop: true
        })
      }
    }
  }
</script>


<style lang="sass">
  .success-message
    font-weight: 900
    font-size: 4.5em
    color: #fff
    width: 3em
    height: 3em
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

    svg
      fill: $light
      position: absolute
      top: calc(50% - 45px)
      left: calc(50% - 45px)
      width: 90px
      transform: scale(.1)
      opacity: 0
      z-index: 2


    .letters-container
      position: absolute
      left: 0
      right: 0
      margin: auto
      top: 0
      bottom: 0
      height: 1em

    .letters
      position: relative
      z-index: 2
      display: inline-block
      line-height: 0.7em
      right: -0.12em
      top: -0.2em

    .bang
      font-size: 1.4em
      top: auto
      left: -0.06em

    .circle
      position: absolute
      left: 0
      right: 0
      margin: auto
      top: 0
      bottom: 0

    .circle-white
      width: 3em
      height: 3em
      border: 2px dashed $primary
      border-radius: 2em

    .circle-dark
      width: 2.2em
      height: 2.2em
      background-color: $primary
      border-radius: 3em
      z-index: 1

    .circle-dark-dashed
      border-radius: 2.4em
      background-color: transparent
      border: 2px dashed $primary
      width: 2.3em
      height: 2.3em

</style>
